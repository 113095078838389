export const defaultUser = { loggedIn: false, fullName: null, email: null };

export const generateUser = user => {
  if (!user) {
    return { ...defaultUser };
  }

  const { user_metadata, email, id } = user;
  const { full_name } = user_metadata;

  const newUser = {
    loggedIn: true,
    fullName: full_name,
    email: email,
    id: id
  };

  return newUser;
};

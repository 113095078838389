import React from "react";

const Footer = () => {
  return (
    <>
      <footer>
        <p>&copy; Copyright {new Date().getFullYear()}</p>
      </footer>
    </>
  );
};

export default Footer;

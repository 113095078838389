import React, { Component } from "react";
import { MessageContext } from "contexts/messageContext";

export default class Message extends Component {
  static contextType = MessageContext;

  render() {
    const { messages } = this.context;
    return (
      <>
        <div>
          {!messages.length && <div className="spinner"></div>}

          {messages &&
            messages.map(message => {
              const timestampDate = new Date(message.timestamp);
              return (
                <div key={message._id}>
                  <p>
                    <strong>{message.name} </strong>
                    <em>{message.email}</em>
                    <small> [{timestampDate.toUTCString()}]</small>
                  </p>

                  <p>{message.message}</p>
                  <hr />
                </div>
              );
            })}
        </div>
      </>
    );
  }
}

import React from "react";
import netlifyIdentity from "netlify-identity-widget";
import { generateUser, defaultUser } from "utils/userUtils";

export const UserContext = React.createContext({});

export class UserProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: { ...defaultUser }
    };
  }

  login = user => {
    this.setState({
      user: generateUser(user)
    });
  };

  logout = user => {
    this.setState({
      user: { ...defaultUser }
    });
  };

  async componentDidMount() {
    netlifyIdentity.init({ logo: false, setCookie: true });
    netlifyIdentity.on("login", user => this.login(user));
    netlifyIdentity.on("logout", user => this.logout(user));
    netlifyIdentity.on("login", () => netlifyIdentity.close());

    netlifyIdentity.on("error", err => {
      console.log(err);
      netlifyIdentity.open();
    });
    
    this.login(netlifyIdentity.currentUser());
  }

  render() {
    const { children } = this.props;
    const { user } = this.state;

    return (
      <UserContext.Provider
        value={{
          login: this.login,
          logout: this.logout,
          user: user,
          handleIdentity: netlifyIdentity.open
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}

export const UserConsumer = UserContext.Consumer;

import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "mini.css/src/flavors/mini-default.scss";
import { Home, About, Login } from "routes";
import { UserProvider } from "contexts/userContext";
import { MessageProvider } from "contexts/messageContext";

class App extends Component {
  render() {
    return (
      <>
        <UserProvider>
          <MessageProvider>
            <Router>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/about" component={About} />
                <Route exact path="/login" component={Login} />
              </Switch>
            </Router>
          </MessageProvider>
        </UserProvider>
      </>
    );
  }
}

export default App;

import React from "react";
import { callFunctionWithAuthHeaders } from "utils/httpUtils";
import netlifyIdentity from "netlify-identity-widget";

export const MessageContext = React.createContext({});

netlifyIdentity.init({setCookie: true})

export class MessageProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: []
    };

    netlifyIdentity.on("login", this.getMessages);
  }

  async componentDidMount() {
    await this.getMessages();
  }

  getMessages = async () => {
    if (netlifyIdentity && netlifyIdentity.currentUser()) {
      const response = await callFunctionWithAuthHeaders("getMessages");

      this.setState({
        messages: response
      });
    }
  };

  async sendMessage(message) {
    const response = await callFunctionWithAuthHeaders("sendMessage", "POST", {
      message
    });

    return response;
  }

  render() {
    const { children } = this.props;
    const { messages } = this.state;

    return (
      <MessageContext.Provider
        value={{
          messages: messages,
          getMessages: this.getMessages,
          sendMessage: this.sendMessage
        }}
      >
        {children}
      </MessageContext.Provider>
    );
  }
}

export const MessageConsumer = MessageContext.Consumer;

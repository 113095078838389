import React from "react";
import withLayout from "layouts/Layout";
import { UserConsumer } from "contexts/userContext";
import Message from "components/Message/Message";
import PostMessage from "components/PostMessage/PostMessage";

const Home = () => {
  return (
    <>
      <UserConsumer>
        {({ user }) => (
          <>
            {user.loggedIn ? (
              <>
                <div className="row">
                  <div className="col-sm-12 col-md-8">
                    <Message />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <PostMessage />
                  </div>
                </div>
              </>
            ) : (
              <p>Please log in.</p>
            )}
          </>
        )}
      </UserConsumer>
    </>
  );
};

export default withLayout(Home);

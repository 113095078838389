import React from "react";
import { Link } from "react-router-dom";
import { UserConsumer } from "contexts/userContext";

const Header = () => {
  return (
    <UserConsumer>
      {({ user, handleIdentity }) => (
        <>
          <header>
            <div className="container">
              <Link to="/" className="logo">
                DevCPU
              </Link>
              <Link to="/" className="button">
                Home
              </Link>
              <Link to="/about" className="button">
                About
              </Link>
              <button className="button" onClick={() => handleIdentity()}>
                {user.loggedIn ? "Log Out" : "Log In"}
              </button>
            </div>
          </header>
        </>
      )}
    </UserConsumer>
  );
};

export default Header;

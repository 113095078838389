import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";

export default function withLayout(Content) {
  return class extends React.Component {
    render() {
      return (
        <>
          <Header />
          <Content />
          <Footer />
        </>
      );
    }
  };
}

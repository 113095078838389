import React, { Component } from "react";
import { UserConsumer } from "contexts/userContext";

export default class Login extends Component {
  render() {
    return (
      <div>
        <UserConsumer>
          {({ user, handleIdentity }) => {
            if (user.loggedIn) {
              return (
                <>
                  <p>Logged In</p>
                  <button onClick={() => handleIdentity()}>Log Out</button>
                </>
              );
            }

            return <button onClick={() => handleIdentity()}>Log In</button>;
          }}
        </UserConsumer>
      </div>
    );
  }
}

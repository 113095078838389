import React, { Component } from "react";
import { MessageContext } from "contexts/messageContext";

export default class PostMessage extends Component {
  static contextType = MessageContext;

  state = {
    message: "",
    loading: false
  };

  postMessage = async () => {
    const { message } = this.state;
    const { getMessages, sendMessage } = this.context;

    await sendMessage(message);
    await getMessages();
  };

  submitMessage = async e => {
    await e.preventDefault();
    this.setState({
      loading: true
    });
    await this.postMessage();

    this.setState({
      message: "",
      loading: false
    });
  };

  handleInputChange = async e => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  render() {
    const { message, loading } = this.state;
    return (
      <div>
        <form onSubmit={this.submitMessage}>
          <fieldset>
            <legend>Post message</legend>
            <div className="input-group vertical">
              <label htmlFor="message">Message</label>
              <input
                type="text"
                name="message"
                id="message"
                placeholder="Enter a message..."
                onChange={this.handleInputChange}
                value={message}
                autoComplete="off"
              />
            </div>
          </fieldset>

          <button type="submit">
            Submit <span className="icon-mail"></span>
          </button>
          {loading && <span className="spinner"></span>}
        </form>
      </div>
    );
  }
}

import netlifyIdentity from "netlify-identity-widget";

netlifyIdentity.init({ setCookie: true });

const generateHeaders = () => {
  const headers = { "Content-Type": "application/json" };
  if (netlifyIdentity.currentUser()) {
    return netlifyIdentity
      .currentUser()
      .jwt()
      .then(token => {
        return { ...headers, Authorization: `Bearer ${token}` };
      });
  }
  return Promise.resolve(headers);
};

export async function callFunctionWithAuthHeaders(
  functionName,
  method = "get",
  body = null
) {
  const headers = await generateHeaders();

  let fetchOptions = {
    method: method,
    headers
  };

  if (body) {
    fetchOptions.body = JSON.stringify(body);
  }

  const response = await fetch(
    `/.netlify/functions/${functionName}`,
    fetchOptions
  );

  return await response.json();
}
